import React, { Component } from "react";
import axios from "axios" ;

class ContactForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnMobile: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }

    handleOnSubmit = (e) =>{
      e.preventDefault();
    try{
      console.log("Action Called");
      const newContactUs = {
        name: this.state.rnName,
        email: this.state.rnEmail,
        mobile: this.state.rnMobile,
        subject: this.state.rnSubject,
        message: this.state.rnMessage,
      };

      //const api = axios.create({baseURL: "https://us-central1-solutoz-live.cloudfunctions.net"})
      //axios.post("https://us-central1-solutoz-live.cloudfunctions.net/sendEmail", newContactUs)
      //axios.post('/sendEmail', newContactUs)
      console.log ("Contact Details: ", newContactUs);
      fetch('/sendEmail', {
        method: 'POST',
        data: newContactUs,
        header: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
          console.log("ContactService:contactUs", response);
          if (response.statusText === "OK") {
            return ("OK");
          } else {
            console.log ("ERROR : in response Data: ", response )
            return (response.statusText);
          }
        })
        .catch((error) => {
          console.log("ContactService:contactUs error", error);
        });
      }
      catch(err){
        console.log ("Contact Service : ERROR : ", err);
      }

      this.setState({
          rnName: '',
          rnMobile: '',
          rnEmail: '',
          rnSubject: '',
          rnMessage: '',
      });
    }

    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <span className="subtitle">Let's Say Hi</span>
                                <h2 className="title">Contact With Us.</h2>
                                <div className="im_address">
                                    <span>Call us directly:</span>
                                    <a className="link im-hover" href="phone">+91 9108197585</a>
                                </div>
                                <div className="im_address mt--5">
                                    <span>Contact Email:</span>
                                    <a className="link im-hover" href="email">info@solutoz.com</a>
                                </div>
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={this.handleOnSubmit}>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Your email *"
                                        />
                                    </label>

                                    <label htmlFor="mobile">
                                        <input
                                            type="text"
                                            name="mobile"
                                            id="mobile"
                                            value={this.state.rnMobile}
                                            onChange={(e)=>{this.setState({rnMobile: e.target.value});}}
                                            placeholder="Your mobile *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Write a Subject"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Your Message"
                                        />
                                    </label>
                                    <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe" >Submit Now</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="mb_md--30 mb_sm--30">
                                <img src="/assets/images/logo/logo-header-1.png" alt="Logo image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactForm;
