import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import { Link } from 'react-router-dom';

let OfferingContent = [
    {
        images: 'khatacorner',
        title: 'Khata Corner',
        designation: 'Accounting & Bookkeeping',
    },
    {
        images: 'demandsupply',
        title: 'Demand-Supply',
        designation: 'Market Place',
    },
    {
        images: 'cloudaccelerators',
        title: 'Cloud Accelerators',
        designation: 'Cloud Catalysts',
    },

];


class Offerings extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {OfferingContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/offerings/prod-${value.images}.png`} alt="Blog Images"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            {/*<div className="content_footer">
                                <Link to="/blog-details" className="rn-btn btn-opacity">Read More</Link>
                            </div>*/}
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Offerings;
