import React, { Component , Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { slideSlick } from "../utils/helper";
import TeamContent from "../elements/team/TeamContent";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Helmet from "../components/common/Helmet";
import { FiBriefcase , FiLayers , FiChevronsUp, FiChevronUp } from "react-icons/fi";
import Offerings from "../elements/offerings/Offerings";
import Counter from "../elements/counters/Counter";
import AccordionExt from "../elements/Accordion";
import AboutUs from "../blocks/about/AboutUs";


const SlideList = [

    {
        textPosition: 'text-center',
        bgImage: 'bg_image--32',
        category: '',
        title: 'Products.',
        description: 'Specially curated domain specific platforms that can be leveraged to enhance your operational efficiency and provide insights for business decisions to ensure growth.',
        buttonText: 'Read More',
        buttonLink: './#products'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--31',
        category: '',
        title: 'Solutions.',
        description: 'Configurable ready to use digital solutions for jump starting of your business and streamline processes.        ',
        buttonText: 'Read More',
        buttonLink: './#solutions'
    },

    {
        textPosition: 'text-center',
        bgImage: 'bg_image--31',
        category: '',
        title: 'Accelerators.',
        description: 'Tools, Utilities and Accelerators to bring down the effort that can be leveraged in running business.',
        buttonText: 'Read More',
        buttonLink: './#accelerators'
    },

]

const ServiceListOne = [
    {
        icon: <FiBriefcase />,
        title: 'Products',
        description: 'Specially curated Platforms that can be leveraged to enhance your operational efficiency and provide insights for business decisions to ensure growth.'
        //footer-icon:
    },
    {
        icon: <FiLayers />,
        title: 'Solutions',
        description: 'Configurable ready to use Digital Solutions, which can be white-labeled for jump starting of your business and streamline processes.'
    },
    {
        icon: <FiChevronsUp />,
        title: 'Accelerators',
        description: 'Several set of Tools, Utilities and Accelerators to bring down the effort that can be leveraged in running business.'
    },
]


var SolutionOfferings = [
  {
  features : [
    'Dashboards',
    'RoleBased Operations',
    'Accounting & Bookkeeping' ,
    'GST enabled Order & Invoices',
    'Customer Management'
  ],
  usages : [
    'Class Management',
    'Consultants',
    'Services',
    'Manufacturing'
  ]
},
{
  features : [
    'Extensible customizable Forms',
    'Admin panel to reconfigure the suite',
    'Reports, Alerts and notifications' ,
    'Dashboard',
    'Scalable cloud native Contenerization'
  ],
  usages : [
    'Manufacturing',
    'Logistics',
    'CSR',
  ]
},
{
  features : [
    'Easy integration',
    '50+ catalysts available to deploy',
    'Containerization tool' ,
  ],
  usages : [
    'Infra as a Code',
    'Containerization',
    'Social media Connectivity tools and Integrations'
  ]
},
];

class HomePage extends Component{
    constructor (props) {
        super(props)
        this.state = {
            isOpen: false
        }
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
        this.openModal = this.openModal.bind(this)

        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })

    }
    openModal () {
        this.setState({isOpen: true})
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}


    render(){


        //const PostList = BlogContent.slice(0 , 3);
        const TeamList = TeamContent.slice(0, 4);

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }




        return(
            <Fragment>
                <Helmet pageTitle="Solutoz" >
                    <meta charSet="utf-8" />
                    <title>Solutoz - Lets make it Simple.</title>
                    <link rel="canonical" href="http://solutoz.com" />
                </Helmet>

                {/* Start Header Area  */}
                <Header headerPosition="header--fixed logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper" >
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title ">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="btn-default" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Services Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                      <div className="section-title text-center mb--25 mb_sm--0">
                        <span className="subtitle">We Are Into...</span>
                      </div>
                        <div className="row service-main-wrapper">
                          {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a>
                                        {/*<div className="service service__style--2 text-left bg-gray">*/}
                                        <div className="service service__style--2 text-left bg-blue-gray">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description} </p>
                                            </div>
                                            <div className="service-line"></div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Offerings Area  */}
                <div id="offerings" className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Our Offerings</span>
                                    <h2 className="title">Ready To Use Products</h2>
                                    <p>Solutoz is ready to help you with various business solution either to scale your existing solution with its specially curated platforms,
                                    <br/> or to jump start your business with ready to use configurable Digital Solutions. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Offerings column="col-lg-4 col-md-6 col-sm-6 col-12" teamStyle="team-style--bottom" item="3" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}


                {/* Start Counterup Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white">
                    <div className="container">
                        <Counter />
                    </div>
                </div>
                {/* End Counterup Area */}


                {/* Start About Area  */}
                <div id="products" className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/offerings/prod-khatacorner.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">Our Products</span>
                                        <h2 className="title">KhataCorner</h2>
                                        <p>One Stop Solution that will enable you to keep all the accounting and bookkeeping in one place for Your Business. <br/><br/>
                                        Enables SMEs to accelerate their business by going digital with KhataCorner with minimal cost, which is a small portion of saved OPEX.</p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <AccordionExt {...SolutionOfferings[0]}/>
                                    </div>
                                    <div className="about-button mt--30">
                                        <Link to="/contact" className="btn-default">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="solutions" className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 xs-column-reverse ">
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">Our Solutions</span>
                                        <h2 className="title">Demand & Supply</h2>
                                        <p>We have designed a initiative Configurable ready to use digital solutions for jump starting of your business and streamline processes.  <br/>
                                        </p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <AccordionExt {...SolutionOfferings[1]}/>
                                    </div>
                                    <div className="about-button mt--30">
                                        <Link to="/contact" className="btn-default">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/offerings/prod-demandsupply.png" alt="DemandSupply Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="accelerators" className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/offerings/prod-cloudaccelerators.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">Our Accelerators</span>
                                        <h2 className="title">Cloud Accelerators</h2>
                                        <p>A comprehensive set of tools to deploy Cloud infrastructure, we have suite of 50+ Cloud-Catalysts Scripts to deploy IaaS to Public Cloud. <br/>
                                        Easily Integrate our Cloud-Catalysts into your DevOps pipelines.  <br/><br/>
                                        A Simplified Toolkit to Containerise Multi-Tier Applications
                                        </p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <AccordionExt {...SolutionOfferings[2]}/>
                                    </div>
                                    <div className="about-button mt--30">
                                        <Link to="/contact" className="btn-default">Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                {/* Start About Area ----CORE VALUES*/}
                <div id="aboutUs" className="about-area rm-about-style-2 ptb--120 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <AboutUs />
                    </div>
                </div>
                {/* End About Area */}


                {/* Start Brand Area  */}
                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Our Story</span>
                                    <h2 className="title">Solutoz story</h2>
                                    <h6> Coming Soon...</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--30">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/about-video-default.jpg" alt="About Images"/>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='c4y5PO5y8Tc' onClose={() => this.setState({isOpen: false})} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}




                {/* Start Blog Area */}
                <div id="team" className="rn-blog-area pt--120 pb--80 bg_color--5">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center">
                                    <span className="subtitle">Behind Solutoz</span>
                                    <h2 className="title">The Team</h2>
                                    {/*<p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}

                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {TeamList.map((value , i ) => (
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={i}>
                                    <div className="im_box">
                                        <div className="thumbnail">
                                            <Link >
                                                <img className="w-100" src={`/assets/images/solutoz/team-${value.images}.jpg`} alt="Blog Images"/>
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="content_heading">
                                                    <div className="category_list">
                                                        <Link >{value.category}</Link>
                                                    </div>
                                                    <div className="profiletype">
                                                      <Link >{value.title}</Link>
                                                    </div>
                                                    {/*<h4 className="title">
                                                        <Link to="/blog-details">{value.title}</Link>
                                                    </h4>*/}
                                                </div>
                                                <ul className="social-icon" >
                                                    {value.socialNetwork.map((social, index) =>
                                                        <li key={index}><a href={`${social.url}`} target="_blank">{social.icon}</a></li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}

                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default HomePage;
