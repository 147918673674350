import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

const TeamContent = [
    {
        images: '01',
        title: 'Sunil is an Industry Veteran who brings in Application Development and has rich experience bringing in products and services to life.',
        category: 'Sunil Parte ',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/sunilparte'
            },
            {
              icon: <FaTwitter />,
              url: '  https://twitter.com/sunil_parte'
            }
        ]
    },
    {
        images: '02',
        title: 'Nilan is the Technology Strategist. Her passion for technology is across multiple fronts but more so takes a keen interest in the convergence of app- infra technologies.',
        category: 'Nilan Das ',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/nilan-das'
            },
        ]
    },
    {
        images: '03',
        title: 'Ramgopal Sitamraju comes from a core infrastructure background and has worked over the last two decades in enterprise infrastructure services with Fortune 500 clients.',
        category: 'Ramgopal Sitamraju ',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://in.linkedin.com/in/ramgopal-sitamraju'
            },
            {
                icon: <FaTwitter />,
                url: 'https://twitter.com/ramgopal24'
            },
        ]
    },
    {
        images: '04',
        title: ' Satish is our Visual Strategist, and an avid ad film maker with over a decade in the creative world and brings in a unique flavor of visualization to the software world.',
        category: 'Satish Nair ',
        socialNetwork: []
    },
]

export default TeamContent;
