import React, { Component } from "react";
import PageHelmet from "../../components/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ContactForm from "./ContactForm";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{

  constructor (props) {
      super(props)
      this.state = {
          isOpen: false
      }
      this.menuTrigger = this.menuTrigger.bind(this);
      this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
      this.stickyHeader = this.stickyHeader.bind(this);
      this.openModal = this.openModal.bind(this)

      window.addEventListener('load', function() {
          console.log('All assets are loaded');
      })

  }
  openModal () {
      this.setState({isOpen: true})
  }

  menuTrigger() {
      document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }
  CLoseMenuTrigger() {
      document.querySelector('.header-wrapper').classList.remove('menu-open')
  }
  stickyHeader () {}

    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };


    render(){

      window.addEventListener('scroll', function() {
          var value = window.scrollY;
          if (value > 100) {
              document.querySelector('.header--fixed').classList.add('sticky')
          }else{
              document.querySelector('.header--fixed').classList.remove('sticky')
          }
      });

      var elements = document.querySelectorAll('.has-droupdown > a');
      for(var i in elements) {
          if(elements.hasOwnProperty(i)) {
              elements[i].onclick = function() {
                  this.parentElement.querySelector('.submenu').classList.toggle("active");
                  this.classList.toggle("open");
              }
          }
      }

        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                {/*}<Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />*/}
                <Header headerPosition="header--fixed logoresize" logo="all-dark" color="color-black"/>

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--32" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact With Us</h2>
                                    <p>We at Solutoz are happy to help you in scaling up your business. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 text-center">
                                    <span className="subtitle">Our contact address</span>
                                    <h2 className="title">Quick Contact Address</h2>
                                    <p className="description">Write us a note and we’ll get back to you as quickly as possible.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p><a href="tel:+919108197585">+91 9108197585</a></p>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:admin@gmail.com">info@solutoz.com</a></p>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>Bengaluru, Karnataka 560066</p>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactForm />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment>
        )
    }
}
export default Contact
