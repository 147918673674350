import React from 'react';
const AboutUs = () => {
    return (
        <div className="container">
            <div className="row row--20 align-items-center">
                <div className="col-lg-6">
                    <div className="thumbnail">
                        {/*<img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>*/}
                        <img className="w-100" src="/assets/images/about/corevalues.png" alt="Corevalue Images"/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="about-inner inner">
                        <div className="section-title">
                            <span className="subtitle">Core Values</span>
                            <h2 className="title">Solutoz Core Values</h2>
                            <p className="description">We at Solutoz, focused to deliver <br/>
                            Simplified & Easy to Use Solutions through <br/>a Holistic Digital Experience for businesses growth & optimization.</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="about-us-list">
                                    <h5 className="title">Integrity</h5>
                                    <p>Integrity is our foundation, we will evolve and sustain by making decisions with integrity and honesty, it will remain our true north.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="about-us-list">
                                    <h5 className="title">Transparency</h5>
                                    <p>Communication with a clear intent of being forthcoming and presenting facts clearly is a part of our culture. Expression without fear while being humble is a part of our ethos.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="about-us-list">
                                    <h5 className="title">Being Intuitive</h5>
                                    <p>We will strive to create true value addition to our customers business and that would be our primary design principles for our products. Our team mutually internally experience the benefits through this value creation.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="about-us-list">
                                    <h5 className="title">Value Creation</h5>
                                    <p>We will strive to create true value addition to our customers business and that would be our primary design principles for our products.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="about-us-list">
                                    <h5 className="title">Evolve & Adapt</h5>
                                    <p>We will constantly evolve; continuous improvement and innovation will be our mantra. Adapting to developing situations and navigating through change will be a constant driver for business growth.</p>
                                </div>
                            </div>
                        </div>
                        <div className="purchase-btn mt--50">
                            <a className="btn-transparent">WE'RE DIFFERENT VALUES</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutUs;
