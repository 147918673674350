export const slideSlick = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    fade: true,
    easing: "fade",
    adaptiveHeight: true
};
